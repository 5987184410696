import React from 'react';
import {useHistory} from 'react-router-dom';

import * as S from './styles';

import Container from '../../components/Container';

import Content from '../../components/Content';
import CircularButton from '../../components/CircularButton';

import { 
  IoIosArrowBack,
  IoIosArrowForward
} from 'react-icons/io';

// import ilustra1 from '../../assets/01.png';

function Anuncio1() {
  const history = useHistory();

  function nextPage(){
    history.push('/4');
  }

  function backPage(){
    history.push('/3');
  }

  return (
      <Container>
        <CircularButton onClick={backPage}><IoIosArrowBack /></CircularButton>
        <Content>
          <a href="https://www.facebook.com/bancodobrasil/posts/2645013645520488" target='blank'><S.ColorBg /></a>
        </Content>
        <CircularButton onClick={nextPage}><IoIosArrowForward /></CircularButton>
      </Container>
  );
}

export default Anuncio1;