import React from 'react';
import {useHistory} from 'react-router-dom';

import * as S from './styles';

import Container from '../../components/Container';

import Content from '../../components/Content';
import CircularButton from '../../components/CircularButton';

import { 
  IoIosArrowBack,
  IoIosArrowForward
} from 'react-icons/io';

function Anuncio2() {
  const history = useHistory();

  function nextPage(){
    history.push('/anuncio/3');
  }

  function backPage(){
    history.push('/9');
  }

  return (
      <Container>
        <CircularButton onClick={backPage}><IoIosArrowBack /></CircularButton>
        <Content>
          <a href="https://www.bb.com.br/pbb/pagina-inicial/bb-digital/solucoes/app-bb#/" target='blank'><S.ColorBg /></a>
        </Content>
        <CircularButton onClick={nextPage}><IoIosArrowForward /></CircularButton>
      </Container>
  );
}

export default Anuncio2;