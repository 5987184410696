import React from 'react';
import { useHistory } from 'react-router-dom';

import Container from '../../components/Container';
import Header from '../../components/Header';
import Content from '../../components/Content';


import CircularButton from '../../components/CircularButton';

import * as S from './styles';

import apresentacao from '../../assets/apresentacao-bb.png';

import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

function Pageone() {
  const history = useHistory();

  function backPage() {
    history.push('/apresentacao');
  }

  function nextPage() {
    history.push('/2');
  }

  return (
    <Container>
      <CircularButton onClick={backPage}><IoIosArrowBack /></CircularButton>
      <Content>
        <Header>APRESENTAÇÃO</Header>
        <S.Image><img src={apresentacao} alt="Página de apresentação" /></S.Image>
      </Content>
      <CircularButton onClick={nextPage}><IoIosArrowForward /></CircularButton>
    </Container>
  );
}

export default Pageone;