import React, {useState} from 'react';
import { useHistory, Link } from 'react-router-dom';

import Container from '../../components/Container';
import Header from '../../components/Header';
import Content from '../../components/Content';
import Paragraph from '../../components/Paragraph';
import CircularButton from '../../components/CircularButton';
import TextGreen from '../../components/TextGreen';
import Button from '../../components/Button';

import Modal from '../../components/Modal';
import Title from '../../components/Title';

import * as S from './styles';

import ilustra1 from '../../assets/10.png';

import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

function Pagenine() {
  const history = useHistory();
  const [modal, setModal] = useState(false);

  function backPage() {
    history.push('/8');
  }

  function nextPage() {
    history.push('/anuncio/2');
  }

  return (
    <>
      <Modal open={modal} setOpen={() => setModal(!modal)} >
        <Title style={{marginBottom: '20px'}}>Plataforma:</Title>Aplicativo exclusivo para o cadastro no benefício.
      </Modal>
      <Container>
        <CircularButton onClick={backPage}><IoIosArrowBack /></CircularButton>
        <Content>
        <Header>DOMINOX</Header>
            <Paragraph italic>Preencha o diagrama, respeitando os cruzamentos, com as palavras em destaque no texto.</Paragraph>

            <S.Textimage>
              <img src={ilustra1} alt="Ilustração 1" />
              <div>
                <Paragraph>Para os <TextGreen>TRABALHADORES</TextGreen> que já possuem <TextGreen>CADASTRO</TextGreen> no CadÚnico e conta na Caixa Econômica Federal ou no Banco do Brasil o pagamento será efetuado automaticamente.</Paragraph>
                <Paragraph>O Governo irá disponibilizar um aplicativo em que os usuários poderão fazer o cadastro para adquirir o <TextGreen>BENEFÍCIO</TextGreen>. Os trabalhadores poderão fazer download do aplicativo, <TextGreen>PREENCHER</TextGreen> os dados e se cadastrar. A <TextGreen style={{cursor: "pointer"}} onClick={() => setModal(true)}>PLATAFORMA</TextGreen> deve permitir que em até 48 horas os pedidos sejam <TextGreen>PROCESSADOS</TextGreen>. O crédito poderá ser depositado nas contas da Caixa Econômica Federal, Banco do Brasil ou de instituições privadas.</Paragraph>
              </div>
            </S.Textimage>
            <Paragraph>Caso o trabalhador já seja cadastrado no CadÚnico, o aplicativo avisará assim que ele digitar o <TextGreen>NÚMERO</TextGreen> do CPF (Cadastro Pessoa Física). O <TextGreen>PAGAMENTO</TextGreen> do auxílio emergencial será pago em <TextGreen>ETAPAS</TextGreen>.</Paragraph>
            <S.Button><Link to="/dominox"><Button>Jogar!</Button></Link></S.Button>
        </Content>
        <CircularButton onClick={nextPage}><IoIosArrowForward /></CircularButton>
      </Container>
    </>
  );
}

export default Pagenine;