import React, { createRef, useState/*, useEffect*/ } from 'react';
import { useHistory } from 'react-router-dom'

import Container from '../../components/Container';
import Header from '../../components/Header';
import Content from '../../components/Content';
import Paragraph from '../../components/Paragraph';
import InputGame from '../../components/InputGame';
import CircularButton from '../../components/CircularButton';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import Video from '../../assets/videos/codigosecreto.mp4';
import Title from '../../components/Title';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';

import Pencil from '../../components/Icons/Pencil';
import Umbrella from '../../components/Icons/Umbrella';
import Airplane from '../../components/Icons/Airplane';
import ArrowG from '../../components/Icons/ArrowG';
import Plus from '../../components/Icons/Plus';
import Scissor from '../../components/Icons/Scissor';
import Star from '../../components/Icons/Star';
import Heart from '../../components/Icons/Heart';
import Radio from '../../components/Icons/Radio';
import Home from '../../components/Icons/Home';
import Smile from '../../components/Icons/Smile';
import TextGreen from '../../components/TextGreen';

import * as S from './styles';

import ilustra1 from '../../assets/09.png';


function Pagethree() {
  const history = useHistory();
  const input2Ref = createRef();
  const input3Ref = createRef();
  const input4Ref = createRef();
  const input5Ref = createRef();
  const input6Ref = createRef();
  const input7Ref = createRef();
  const input8Ref = createRef();
  const input9Ref = createRef();
  const input10Ref = createRef();
  const input11Ref = createRef();
  const input12Ref = createRef();
  const input13Ref = createRef();
  const input14Ref = createRef();
  const input15Ref = createRef();
  const input16Ref = createRef();
  const input17Ref = createRef();
  const input18Ref = createRef();
  const input19Ref = createRef();
  const input20Ref = createRef();
  const input21Ref = createRef();
  const input22Ref = createRef();
  const input23Ref = createRef();
  const input24Ref = createRef();

  const [points, setPoints] = useState(0);

  const [modal, setModal] = useState(false);

  // useEffect(() => {
  //   setPoints(sessionStorage.getItem('pointsUser'));
  // }, [])

  // useEffect(() => {
  //   sessionStorage.setItem('pointsUser', points);
  // }, [points]);

  // const updatePoints = () => {
  //   let curr_points = sessionStorage.getItem('pointsUser')

  //   if (curr_points > 0) {
  //     setPoints(curr_points - 20);
  //   }
  // }

  const RESULT = "deatetressalariosminimos";
  const [word, setWord] = useState("                        ");
  const [active, setActive] = useState(false);
  function backPage() {
    history.push('/2');
  }

  function nextPage() {
    history.push('/anuncio/1');
  }

  function validate(idx, ref, event) {
    if (event.target.value.length <= 0 || !ref.current) {
      return
    }
    setWord(word.substring(0, idx) + event.target.value + word.substring(idx + 1))
    ref.current.focus();

    // if (RESULT[idx] !== event.target.value.toLowerCase()) {
    //   let curr_points = sessionStorage.getItem('pointsUser');

    //   if (curr_points >= 5) {
    //     setPoints(curr_points - 5);
    //   } else {
    //     setPoints(0);
    //     history.push('/');
    //   }
    // }
  }

  return (
    <Container>
      <Modal open={modal} setOpen={() => setModal(!modal)} >
        <Title style={{ marginBottom: '20px' }}>Auxílio Emergencial:</Title>Benefício concedido pelo Governo durante a pandemia.
      </Modal>
      <CircularButton onClick={backPage}><IoIosArrowBack /></CircularButton>
      <Content>
        <Header chronometer /*onTimeout={() => updatePoints()}*/ points={points}>CÓDIGO SECRETO</Header>
        <Paragraph>O Governo irá verificar a renda média dos inscritos no programa de <TextGreen style={{ cursor: "pointer" }} onClick={() => setModal(true)}>auxílio emergencial</TextGreen> através do cadastro único. Há também cidadãos que não pertencem a nenhum cadastro. Nesses casos, é preciso que, no último mês anterior ao recebimento do benefício, a renda familiar mensal por pessoa tenha sido de, no máximo, meio salário mínimo.</Paragraph>
        <Paragraph italic>Substitua os códigos pelas letras correspondentes e descubra a renda familiar mensal total para que esse cidadão tenha direito ao benefício.</Paragraph>
        <S.Image><img src={ilustra1} alt="Ilustração 1" /></S.Image>

        <S.List>
          <ul>
            <li><Pencil /><InputGame disabled value='A' /></li>
            <li><Umbrella /><InputGame disabled value='D' /></li>
            <li><Airplane /><InputGame disabled value='E' /></li>
            <li><ArrowG /><InputGame disabled value='I' /></li>
            <li><Plus /><InputGame disabled value='L' /></li>
            <li><Scissor /><InputGame disabled value='M' /></li>
            <li><Star /><InputGame disabled value='N' /></li>
            <li><Heart /><InputGame disabled value='O' /></li>
            <li><Radio /><InputGame disabled value='R' /></li>
            <li><Home /><InputGame disabled value='S' /></li>
            <li><Smile /><InputGame disabled value='T' /></li>
          </ul>
        </S.List>

        <S.List2>
          <div>
            <ul>
              <li>
                <Umbrella />
                <InputGame
                  success={word[0] === " " || word[0] === "" ? null : RESULT[0] === word[0].toLowerCase()}
                  onChange={(event) => {
                    validate(0, input2Ref, event);
                  }}
                />
              </li>
              <li>
                <Airplane />
                <InputGame
                  success={word[1] === " " || word[1] === "" ? null : RESULT[1] === word[1].toLowerCase()}
                  ref={input2Ref}
                  onChange={(event) => {
                    validate(1, input3Ref, event);
                  }}
                />
              </li>
            </ul>
            <ul>
              <li>
                <Pencil />
                <InputGame
                  ref={input3Ref}
                  success={word[2] === " " || word[2] === "" ? null : RESULT[2] === word[2].toLowerCase()}
                  onChange={(event) => {
                    validate(2, input4Ref, event);
                  }}
                /></li>
              <li>
                <Smile />
                <InputGame
                  ref={input4Ref}
                  success={word[3] === " " || word[3] === "" ? null : RESULT[3] === word[3].toLowerCase()}
                  onChange={(event) => {
                    validate(3, input5Ref, event);
                  }}
                />
              </li>
              <li>
                <Airplane />
                <InputGame
                  ref={input5Ref}
                  success={word[4] === " " || word[4] === "" ? null : RESULT[4] === word[4].toLowerCase()}
                  onChange={(event) => {
                    validate(4, input6Ref, event);
                  }}
                />
              </li>
            </ul>
            <ul>
              <li>
                <Smile />
                <InputGame
                  ref={input6Ref}
                  success={word[5] === " " || word[5] === "" ? null : RESULT[5] === word[5].toLowerCase()}
                  onChange={(event) => {
                    validate(5, input7Ref, event);
                  }}
                />
              </li>
              <li>
                <Radio />
                <InputGame
                  ref={input7Ref}
                  success={word[6] === " " || word[6] === "" ? null : RESULT[6] === word[6].toLowerCase()}
                  onChange={(event) => {
                    validate(6, input8Ref, event);
                  }}
                />
              </li>
              <li>
                <Airplane />
                <InputGame
                  ref={input8Ref}
                  success={word[7] === " " || word[7] === "" ? null : RESULT[7] === word[7].toLowerCase()}
                  onChange={(event) => {
                    validate(7, input9Ref, event);
                  }}
                />
              </li>
              <li>
                <Home />
                <InputGame
                  ref={input9Ref}
                  success={word[8] === " " || word[8] === "" ? null : RESULT[8] === word[8].toLowerCase()}
                  onChange={(event) => {
                    validate(8, input10Ref, event);
                  }}
                />
              </li>
            </ul>
          </div>

          <div>
            <ul>
              <li>
                <Home />
                <InputGame
                  ref={input10Ref}
                  success={word[9] === " " || word[9] === "" ? null : RESULT[9] === word[9].toLowerCase()}
                  onChange={(event) => {
                    validate(9, input11Ref, event);
                  }}
                />
              </li>
              <li>
                <Pencil />
                <InputGame
                  ref={input11Ref}
                  success={word[10] === " " || word[10] === "" ? null : RESULT[10] === word[10].toLowerCase()}
                  onChange={(event) => {
                    validate(10, input12Ref, event);
                  }}
                />
              </li>
              <li>
                <Plus />
                <InputGame
                  ref={input12Ref}
                  success={word[11] === " " || word[11] === "" ? null : RESULT[11] === word[11].toLowerCase()}
                  onChange={(event) => {
                    validate(11, input13Ref, event);
                  }}
                />
              </li>
              <li>
                <Pencil />
                <InputGame
                  ref={input13Ref}
                  success={word[12] === " " || word[12] === "" ? null : RESULT[12] === word[12].toLowerCase()}
                  onChange={(event) => {
                    validate(12, input14Ref, event);
                  }}
                />
              </li>
              <li>
                <Radio />
                <InputGame
                  ref={input14Ref}
                  success={word[13] === " " || word[13] === "" ? null : RESULT[13] === word[13].toLowerCase()}
                  onChange={(event) => {
                    validate(13, input15Ref, event);
                  }}
                />
              </li>
              <li>
                <ArrowG />
                <InputGame
                  ref={input15Ref}
                  success={word[14] === " " || word[14] === "" ? null : RESULT[14] === word[14].toLowerCase()}
                  onChange={(event) => {
                    validate(14, input16Ref, event);
                  }}
                />
              </li>
              <li>
                <Heart />
                <InputGame
                  ref={input16Ref}
                  success={word[15] === " " || word[15] === "" ? null : RESULT[15] === word[15].toLowerCase()}
                  onChange={(event) => {
                    validate(15, input17Ref, event);
                  }}
                />
              </li>
              <li>
                <Home />
                <InputGame
                  ref={input17Ref}
                  success={word[16] === " " || word[16] === "" ? null : RESULT[16] === word[16].toLowerCase()}
                  onChange={(event) => {
                    validate(16, input18Ref, event);
                  }}
                />
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li>
                <Scissor />
                <InputGame
                  ref={input18Ref}
                  success={word[17] === " " || word[17] === "" ? null : RESULT[17] === word[17].toLowerCase()}
                  onChange={(event) => {
                    validate(17, input19Ref, event);
                  }}
                />
              </li>
              <li>
                <ArrowG />
                <InputGame
                  ref={input19Ref}
                  success={word[18] === " " || word[18] === "" ? null : RESULT[18] === word[18].toLowerCase()}
                  onChange={(event) => {
                    validate(18, input20Ref, event);
                  }}
                />
              </li>
              <li>
                <Star />
                <InputGame
                  ref={input20Ref}
                  success={word[19] === " " || word[19] === "" ? null : RESULT[19] === word[19].toLowerCase()}
                  onChange={(event) => {
                    validate(19, input21Ref, event);
                  }}
                />
              </li>
              <li>
                <ArrowG />
                <InputGame
                  ref={input21Ref}
                  success={word[20] === " " || word[20] === "" ? null : RESULT[20] === word[20].toLowerCase()}
                  onChange={(event) => {
                    validate(20, input22Ref, event);
                  }}
                />
              </li>
              <li>
                <Scissor />
                <InputGame
                  ref={input22Ref}
                  success={word[21] === " " || word[21] === "" ? null : RESULT[21] === word[21].toLowerCase()}
                  onChange={(event) => {
                    validate(21, input23Ref, event);
                  }}
                />
              </li>
              <li>
                <Heart />
                <InputGame
                  ref={input23Ref}
                  success={word[22] === " " || word[22] === "" ? null : RESULT[22] === word[22].toLowerCase()}
                  onChange={(event) => {
                    validate(22, input24Ref, event);
                  }}
                />
              </li>
              <li>
                <Home />
                <InputGame
                  ref={input24Ref}
                  success={word[23] === " " || word[23] === "" ? null : RESULT[23] === word[23].toLowerCase()}
                  onChange={(event) => {
                    validate(23, input24Ref, event);
                  }}
                />
              </li>
            </ul >
          </div>
        </S.List2>
        <Button onClick={() => setActive(!active)}>Como Jogar?</Button>
      </Content>
      {
        active === true &&
        <Modal open={active} setOpen={() => setActive(!active)} src={Video} />
      }
      <CircularButton onClick={nextPage}><IoIosArrowForward /></CircularButton>
    </Container >
  );
}

export default Pagethree;