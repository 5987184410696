import React from 'react';
import {useHistory} from 'react-router-dom';

import * as S from './styles';

import Container from '../../components/Container';

import Content from '../../components/Content';
import CircularButton from '../../components/CircularButton';

import { 
  IoIosArrowBack,
  IoIosArrowForward
} from 'react-icons/io';

function Anuncio3() {
  const history = useHistory();

  function nextPage(){
    history.push('/final');
  }

  function backPage(){
    history.push('/anuncio/2');
  }

  return (
      <Container>
        <CircularButton onClick={backPage}><IoIosArrowBack /></CircularButton>
        <Content>
          <a href="http://www.bb.com.br/auxilio" target='blank'><S.ColorBg /></a>
        </Content>
        <CircularButton onClick={nextPage}><IoIosArrowForward /></CircularButton>
      </Container>
  );
}

export default Anuncio3;