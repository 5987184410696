import React from 'react';
import { ThemeProvider } from 'styled-components';
import { 
  HashRouter as Router, 
  Switch,
  Route
} from 'react-router-dom';

import theme from '../theme';

// import PageVideo from '../pages/PageVideo';
import Pageone from '../pages/Pageone';
import Pagetwo from '../pages/Pagetwo';
import Pagethree from '../pages/Pagethree';
import Pagefour from '../pages/Pagefour';
import Pagefive from '../pages/Pagefive';
import Pagesix from '../pages/Pagesix';
import Pageseven from '../pages/Pageseven';
import Pageeight from '../pages/Pageeight';
import Pagenine from '../pages/Pagenine';
import Anuncio1 from '../pages/Anuncio1';
import Anuncio2 from '../pages/Anuncio2';
import Anuncio3 from '../pages/Anuncio3';
import Cacapalavra1 from '../pages/Cacapalavra1';
import Cacapalavra2 from '../pages/Cacapalavra2';
import Dominox from '../pages/Dominox';
import Capa from '../pages/Capa';
import FinalPage from '../pages/FinalPage';

import * as S from './styles';

import logoCoquetel from '../assets/logo-coquetel.png';
import ortobomLogo from '../assets/logobb.png';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <S.Container>
          <div className="logo-container">
            <img className="logo-coquetel logo" src={logoCoquetel} alt='logotipo Coquetel'/>
            <img className="logo-parceiro logo" src={ortobomLogo} alt='logotipo Parceiro'/>
          </div>
          <Switch>
            <Route path="/1">
              <Pageone/>
            </Route>
            <Route path="/2">
              <Pagetwo/>
            </Route>
            <Route path="/3">
              <Pagethree/>
            </Route>
            <Route path="/4">
              <Pagefour/>
            </Route>
            <Route path="/5">
              <Pagefive/>
            </Route>
            <Route path="/6">
              <Pagesix/>
            </Route>
            <Route path="/7">
              <Pageseven/>
            </Route>
            <Route path="/8">
              <Pageeight/>
            </Route>
            <Route path="/9">
              <Pagenine/>
            </Route>
            <Route path="/anuncio/1">
              <Anuncio1/>
            </Route>
            <Route path="/anuncio/2">
              <Anuncio2/>
            </Route>
            <Route path="/anuncio/3">
              <Anuncio3/>
            </Route>
            <Route path="/cacapalavra/1">
              <Cacapalavra1/>
            </Route>
            <Route path="/cacapalavra/2">
              <Cacapalavra2/>
            </Route>
            <Route path="/dominox">
              <Dominox/>
            </Route>
            {/* <Route path="/apresentacao">
              <PageVideo/>
            </Route> */}
            <Route path="/final">
              <FinalPage/>
            </Route>
            <Route path="/">
              <Capa/>
            </Route>
            </Switch>
            <p className="mercadata">Powered by Mercadata Digital, 2020.</p>
        </S.Container>
      </Router>
    </ThemeProvider>
  );
}

export default App;
